import * as React from 'react';
import AOS from 'aos';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HSVideoBg from '../../../js/vendor/hs-video-bg/hs-video-bg';
import StatCircle from '../../../components/statCircle';
import Typed from 'react-typed';

import fitbitLogo from '../../../assets/svg/brands/fitbit-dark.svg';
import forbesLogo from '../../../assets/svg/brands/forbes-dark.svg';
import mailchimpLogo from '../../../assets/svg/brands/mailchimp-dark.svg';
import layerLogo from '../../../assets/svg/brands/layar-dark.svg';
//@ts-ignore
import { ReactComponent as Gen020 } from '../../../assets/svg/duotone-icons/gen020.svg'
import { ReactComponent as Cod012 } from '../../../assets/svg/duotone-icons/cod012.svg'
import { ReactComponent as Cod011 } from '../../../assets/svg/duotone-icons/cod011.svg'
import { ReactComponent as Cod009 } from '../../../assets/svg/duotone-icons/cod009.svg'
import { ReactComponent as Txt009 } from '../../../assets/svg/duotone-icons/txt009.svg'
import { ReactComponent as Art006 } from '../../../assets/svg/duotone-icons/art006.svg'
import { Link } from 'react-router-dom';

export function HomeComponent() {
    const { t, i18n } = useTranslation();
    const [modal,showModal]=React.useState(false);
    useEffect(() => {
        document.title = "Home - Hartmann";
        AOS.init({
            duration: 650,
            once: true
        });
        AOS.refresh();
        //setTimeout( ()=> showModal(true),2000);
    });


    return (
        <React.Fragment>
            <HeroSection />
           
            {/* notification EVENT */}
            {modal&&
                
                <div className="container position-fixed top-50 start-50 translate-middle zi-3">
                    <div className="alert alert-white" role="alert">
                        <div style={{ display: "flex" }}>
    
                            <h1 className="text-dark text-center" style={{ display: "inline", width: "100%" }}>Join us at ISC West 2024!</h1>
                            <i className="button bi bi-x-lg" data-bs-dismiss="alert"></i>
                        </div>
    
                        <a href={"http://nvyt.es/f59f5607b7c0bef37d35"}><img className="card-img" src={"/images/iscinvite2024.png"} /></a>
    
                        <div className="col-center-sm-4 text-sm-end">
    
                        </div>
                    </div>
                </div>
                }
            {/* notification EVENT */}

            <div className="container content-space-t-2 content-space-t-lg-3 content-space-b-lg-2">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <span className="text-cap">state-of-the-art</span>
                    <h2>Access Control Solutions</h2>
                </div>

                <ul className="step step-md step-centered">
                    <li className="step-item">
                        <div className="step-content-wrapper">
                            <span className="step-icon gold-background-white-text">1</span>
                            <div className="step-content">
                                <h3>Installer friendly product</h3>
                                <p>A product that saves you time and money on installs.</p>
                            </div>
                        </div>
                    </li>

                    <li className="step-item">
                        <div className="step-content-wrapper">
                            <span className="step-icon gold-background-white-text">2</span>
                            <div className="step-content">
                                <h3>Limitless capabilities</h3>
                                <p>Cutting-edge scripting engine that allows you to tailor the system to your needs.</p>
                            </div>
                        </div>
                    </li>

                    <li className="step-item">
                        <div className="step-content-wrapper">
                            <span className="step-icon gold-background-white-text">3</span>
                            <div className="step-content">
                                <h3>Unrivaled support</h3>
                                <p>Our support lines have little to no wait times and we offer onsite support when required.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="container">
                <div className="row  pb-5">

                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <a className="card card-transition align-items-end flex-wrap flex-row bg-img-start h-100" href="marketverticals/education" style={{ backgroundImage: "url('/images/cards/card-1.jpg')", minHeight: "25rem" }} data-aos="fade-up">
                            <div className="card-body">
                                <h2 className="card-title text-white">No matter the size business we have your back! </h2>
                                <p className="card-text text-white">From educational institutions, places of worship, healthcare, and much more.</p>
                                <span className="card-link link-light">Learn more <i className="bi-chevron-right small ms-1"></i></span>
                            </div>
                        </a>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
                        <a className="card card-transition align-items-end flex-wrap flex-row bg-img-start h-100" href="casestudies/all" style={{ backgroundImage: "url('/images/cards/card-2.jpg')", minHeight: "25rem" }} data-aos="fade-up" data-aos-delay="100">
                            <div className="card-body">
                                <h3 className="card-title text-white">How the flexibility of our system has helped people like you</h3>
                                <span className="card-link link-light">Learn more <i className="bi-chevron-right small ms-1"></i></span>
                            </div>
                        </a>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <a className="card card-transition align-items-end flex-wrap flex-row bg-img-start h-100" href="company/aboutus" style={{ backgroundImage: "url('/images/cards/card-3.jpg')", minHeight: "25rem" }} data-aos="fade-up" data-aos-delay="150">
                            <div className="card-body">
                                <h3 className="card-title text-white">Why Hartmann?</h3>
                                <span className="card-link link-light">Learn more <i className="bi-chevron-right small ms-1"></i></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="position-relative mt-5">
                <div className="container content-space-lg-3">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-9 mb-7 mb-md-0">
                            <div className="w-md-65 mb-7">
                                <h2 className="h1">Hartmann has the tools to support you!</h2>
                                <p>Our complete product line ensures you have the right equipment for the job. Whether you need to secure a single door or an entire building, Hartmann has the tools to support you in achieving your security goals. With a wide range of products and solutions, you can trust that Hartmann has the expertise to help you find the perfect fit for all your needs.</p>
                            </div>

                            <div className="row ">
                                <div className="col-md-4 mb-3 mb-md-0">
                                    <div className="card h-100" data-aos="fade-up">
                                        <div className="card-body" >
                                            <span className="svg-icon text-primary mb-3">
                                            </span>
                                            <h4 className="card-title">Software Solutions</h4>
                                            <p className="card-text">We are proud to offer limitless capabilities and customization with Odyssey and PROTECTOR.Net.</p>
                                            <a className="card-link" href="products/odyssey/software">Learn more <i className="bi-chevron-right small ms-1"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3 mb-md-0">
                                    <div className="card h-100" data-aos="fade-up">
                                        <div className="card-body">
                                            <span className="svg-icon text-primary mb-3">
                                            </span>
                                            <h4 className="card-title">Hardware Solutions</h4>
                                            <p className="card-text">Select from our patented PoE to our traditional IP panel mount controllers to best suit your installation needs.</p>
                                            <a className="card-link" href="products/odyssey/hardware">Learn more <i className="bi-chevron-right small ms-1"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card h-100" data-aos="fade-up">
                                        <div className="card-body">
                                            <span className="svg-icon text-primary mb-3">
                                            </span>
                                            <h4 className="card-title">Mobile Solutions</h4>
                                            <p className="card-text">Unlock doors, execute action plans, and much more using our mobile apps for both Android and iOS. </p>
                                            <a className="card-link" href="products/mobile/admin">Learn more <i className="fas bi-chevron-right small ms-1"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="banner-half-middle-x bg-img-start d-none d-md-block" data-aos="fade-up"
                    style={{ backgroundImage: "url('/images/900x900/img1.jpg')" }}></div>
            </div>
            <StatCircle value={10} maxValue={15} color1='' secondaryText='Testing a sentence here' duration={1200} />
            <Features />
            <div className="container">

                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <span className="text-cap"></span>
                    <br />
                    <h2>What Hartmann offers you</h2>
                </div>

                <div className="row mb-5 mb-md-9">
                    <div className="col-sm-6 col-md-4 mb-3 mb-sm-7" data-aos="fade-up" >
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <span className="svg-icon svg-icon-sm text-primary">
                                    <Gen020/>
                                </span>
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <h4 className="mb-0">Premium quality</h4>
                            </div>
                        </div>

                        <p>With all manufacturing done in Canada, this allows us to provide short product lead times and strong product quality control.</p>
                    </div>

                    <div className="col-sm-6 col-md-4 mb-3 mb-sm-7" data-aos="fade-up">
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <span className="svg-icon svg-icon-sm text-primary">
                                    <Cod012/>
                                </span>
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <h4 className="mb-0">Unrivaled support</h4>
                            </div>
                        </div>

                        <p>Helping you during quoting, installation and maintenance process. Direct communication with upper management all to make your installation as pleasant as possible.</p>
                    </div>

                    <div className="col-sm-6 col-md-4 mb-3 mb-sm-7 mb-md-0" data-aos="fade-up">
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <span className="svg-icon svg-icon-sm text-primary">
                                    <Cod011/>
                                </span>
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <h4 className="mb-0">Open API</h4>
                            </div>
                        </div>

                        <p>Completely automate your installs by tying multiple systems to ours via our scripting engine and API.</p>
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3 mb-sm-7 mb-md-0" data-aos="fade-up">
                        <div className="d-flex align-items-center mb-2" >
                            <div className="flex-shrink-0">
                                <span className="svg-icon svg-icon-sm text-primary">
                                    <Cod009/>
                                </span>
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <h4 className="mb-0">Serviceable</h4>
                            </div>
                        </div>

                        <p>All our controllers are designed to be easily Serviceable from our on-board LCD's, allowing for full diagnostics.</p>
                    </div>

                    <div className="col-sm-6 col-md-4 mb-3 mb-sm-7 mb-md-0" data-aos="fade-up">
                        <div className="d-flex align-items-center mb-2" >
                            <div className="flex-shrink-0">
                                <span className="svg-icon svg-icon-sm text-primary">
                                    <Txt009/>
                                </span>
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <h4 className="mb-0">Rich feature set</h4>
                            </div>
                        </div>
                        <p>We take pride in having one of the most powerful feature sets out there. Query third-party systems, run custom scripts and use your system to it's full potential.</p>
                    </div>
                    <div className="col-sm-6 col-md-4" data-aos="fade-up">
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <span className="svg-icon svg-icon-sm text-primary">
                                    <Art006/>
                                </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h4 className="mb-0">Scalable</h4>
                            </div>
                        </div>
                        <p>For over 20+ years our systems have been installed in school boards, hospitals, banking chains, condos, and more.</p>
                    </div>
                </div>
                <div className="text-center">
                    <div className="d-grid d-sm-flex justify-content-center gap-2 mb-3">
                        <Link className="btn btn-primary btn-transition" to="contact">Contact us</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const Features: React.FC = () => {
    return (
        <div className="position-relative bg-light rounded-2 mx-3 mx-lg-10">
            <div className="container content-space-2 content-space-lg-3">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5">
                    <h2>Experience the next generation of access control with Odyssey</h2>
                    <p>Our brand new access control system</p>
                </div>

                <div className="text-center mb-10">
                    <ul className="list-inline list-checked list-checked-primary">
                        <li className="list-inline-item list-checked-item">Improved performance</li>
                        <li className="list-inline-item list-checked-item">Optimized for larger system</li>
                        <li className="list-inline-item list-checked-item">Refreshed User Interface</li>
                    </ul>
                </div>

                <div className="row">
                    <div className="col-lg-7 mb-9 mb-lg-0">
                        <div className="pe-lg-6">
                            <figure className="device-browser">
                                <div className="device-browser-header">
                                    <div className="device-browser-header-btn-list">
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                    </div>
                                    <div className="device-browser-header-browser-bar">https://odyssey:11001</div>
                                </div>

                                <div className="device-browser-frame">
                                    {/* <img className="device-browser-img" src="/images/OdysseySoftware/ACEsolutions.png" alt="Image Description" /> */}
                                    <img className="device-browser-img" src="/images/OdysseySoftware/RefreshedUI.png" alt="Image Description" style={{ maxWidth: "100%" }} />
                                </div>
                            </figure>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="mb-4">
                            <h2>Unlock Your Access Control Potential</h2>
                            <p>With a customizable dashboard, IP-level DSC integration, mobile app enhancements, embedded badge design, and precise administrator permissions provide the ultimate user experience for security management.</p>
                        </div>

                        <ul className="list-checked list-checked-primary mb-5">
                            <li className="list-checked-item">Schlage ENGAGE Integration</li>
                            <li className="list-checked-item">Health Tracker</li>
                            <li className="list-checked-item">Search and Filter Improvements</li>
                        </ul>

                        <a className="btn btn-primary" href="https://portal.hartmann-controls.com" target="_blank" rel="nonreferrer">Download Odyssey</a>

                        <hr className="my-5" />

                    </div>
                </div>
            </div>
        </div>

    );
}


const HeroSection: React.FC = () => {
    useEffect(() => {
        document.querySelectorAll('.js-video-bg').forEach(item => {
            new HSVideoBg(item).init()
        })
        return () => { }
    }, [])
    return (
        <React.Fragment>
            <div className="container-fluid px-lg-5">
                <div className="video-bg ">
                    <div className="js-video-bg video-bg-content"
                        data-hs-video-bg-options='{
                            "videoId": "/images/videos/websiteloop"
                        }'
                    >
                        <div className="container content-space-t-5 content-space-b-1 content-space-b-sm-2">
                            <div className="w-xl-75 w-xxl-50 position-absolute zi-3 ">
                                <br />
                                <br />{/*  Can't seem to figure out a cleaner way to do this without using br's */}
                                <br />
                                <br />
                                <h1 className="display-3 mb-5 text-white">
                                    Changing the way the world manages&nbsp;
                                    <Typed className="js-typedjs text-highlight-warning text-gold"
                                        strings={["buildings", "spaces", " people"]}
                                        typeSpeed={70}
                                        backSpeed={40}
                                        backDelay={2000}
                                        loop={true}
                                    />

                                </h1>

                                {/* <p className="lead text-white">As well as being game-changers when it comes to theme innovation, HS has some of the bestselling themes in its locker.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="video-bg-replacer-img" >
                        <video autoPlay={true} muted={true} loop={true} width="100%">
                            <source src="/images/videos/websiteloop.webm" type='video/webm; codecs="vp9"'></source>
                            <source src="/images/videos/websiteloop.mp4" type='video/mp4; codecs="avc"' ></source>
                        </video>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}
